import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { IClaim } from 'types/Claim';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';


const ClaimDetailsReasonCode = ({ claim }: { claim: IClaim }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2.5,
        justifyContent: 'space-between',
        display: 'flex',
        borderRadius: 0.5,
        width: !!desktop ? '1344px' : '100%',
        maxWidth: '1344px',
      }}
    >
      <Stack flexGrow={1} rowGap={1.875}>
        <Typography variant="body">
          <Trans
            i18nKey={t('claim.labels.reasonCode')}
            values={{ reasonCode: claim?.reasonCode }}
            components={{
              bold: (
                <Typography
                  sx={{ ml: 1 }}
                  variant={!!desktop ? 'bodyLink' : 'bodyLink'}
                />
              ),
            }}
          />
        </Typography>
        <Typography variant="body">
          <Trans
            i18nKey={t('claim.labels.internalNote')}
            values={{ internalNote: claim?.internalNote ? claim.internalNote : '-' }}
            components={{
              bold: (
                <Typography
                  sx={{ ml: 1 }}
                  variant={!!desktop ? 'bodyLink' : 'bodyLink'}
                />
              ),
            }}
          />
        </Typography>
      </Stack>
    </Paper>
  )
}

export default ClaimDetailsReasonCode
