import { useMediaQuery, useTheme } from '@mui/material';

import Button from 'styles/button/Button';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useTranslation } from 'react-i18next';


type ContainerFooter = {
	ml?: string
}  

export const FooterDesktop: React.FC<ContainerFooter> = props => {
	const { t } = useTranslation();
	const year = new Date().getFullYear();
	const theme = useTheme();
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));

	return (
		<Stack
			sx={{ borderTop: 1, ml:!!props.ml ? props.ml : 0 , width: '100%'}}
			direction={'row'}
			height={!!desktop ? '70px' : '60px'
			}
			alignItems={'center'}
		>
			<Stack flexGrow={1}>
				<Typography variant="caption">{`© ${year} ${t(
					'sharedLayout.footerRightReserved',
				)}`}</Typography>
			</Stack>
			<Stack flexGrow={0} direction={'row'} gap={!!desktop ? 4.75 : 2.5}>
				<Button variant="link">
					<Typography variant="caption">
						{t('sharedLayout.footerConditions')}
					</Typography>
				</Button>
				<Button variant="link">
					<Typography variant="caption">
						{t('sharedLayout.footerPrivacy')}
					</Typography>
				</Button>
				<Button variant="link">
					<Typography variant="caption">{t('sharedLayout.footerCookie')}</Typography>
				</Button>
			</Stack>
		</Stack>
	
	);
};
