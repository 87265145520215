import styled from 'styled-components';

interface Props {
	status: string;
}

export const StatusIcon = styled.span<Props>`
	height: 8px;
	width: 8px;
	background-color: ${Props =>
		Props.status.toString().toLowerCase() === 'pending documentation' ||
		Props.status.toString().toLowerCase() === 'paid_voided'
			? '#B4B4B4'
			: Props.status.toString().toLowerCase() === 'submitted'
			? '#E0A93E'
			: Props.status.toString().toLowerCase() === 'paid' ||
			  Props.status.toString().toLowerCase() === 'approved'
			? '#55A63A'
			: '#902727'};
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
`;
