import { Checkbox, IconButton, Paper, Stack, Typography } from 'styles';
import { getClaimStatusProperty, getShortDate } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ChevronRightIcon } from 'assett/icons/chevron_right_icon.svg';
import { FC } from 'react';
import { IClaims } from 'types/Claims';
import { RootState } from 'store/store';
import { StatusIcon } from './StatusIcon.component';
import { setSelectOne } from 'feature/claims/claimsSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ClaimsList: FC = () => {
	const { t } = useTranslation();
	const { claims, claimIds } = useSelector((store: RootState) => store.claims);
	const navigate = useNavigate();
	const { isDesktop } = useDevice();
	const progressIndicator = useSelector(
		(state: RootState) => state.progressIndicator,
	);

	const handleClick = (id: number) => {
		navigate(`/view-claims/${id}`);
	};

	const dispatch = useDispatch<any>();

	const List = claims?.map((claim: IClaims) => {
		return (
			<Paper
				variant="outlined"
				sx={{
					borderWidth: 0,
					mb: 0.5,
					minWidth: 'fit-content',
				}}
				key={claim.claimId}
			>
				<Stack
					direction={'row'}
					alignItems={'center'}
					//height={!!isDesktop ? 52 : 38}
					height={'auto'}
					sx={{ p: !!isDesktop ? 1.25 : 0.8 }}
				>
					<Stack flexGrow={1} direction={'row'} alignItems={'center'}>
						<Checkbox
							label=""
							key={claim.claimId}
							sx={{ p: 0, ml: 0 }}
							checked={claimIds.includes(claim.claimId)}
							onClick={() => {
								dispatch(setSelectOne(claim.claimId));
							}}
						/>

						<Typography
							variant="body"
							sx={{ width: !!isDesktop ? 245 : 220, ml: 1.5 }}
						>
							{`#${claim.externalClaimId}`}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 245 : 220 }}>
							{claim.memberFirstName}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 245 : 220 }}>
							{claim.memberLastName}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 336 : 220 }}>
							{getShortDate(claim.claimDate)}
						</Typography>
						<Typography variant="body" sx={{ width: !!isDesktop ? 300 : 290 }}>
							<StatusIcon status={claim.claimStatus.toLowerCase()} />
							{t(`forms.status.${getClaimStatusProperty(claim.claimStatusId)}`)}
						</Typography>
					</Stack>
					<Stack flexGrow={0}>
						<IconButton
							onClick={() => handleClick(claim.claimId)}
							sx={{ cursor: 'pointer' }}
						>
							<ChevronRightIcon />
						</IconButton>
					</Stack>
				</Stack>
			</Paper>
		);
	});

	return (
		<Stack sx={{ mb: 0.5 }}>
			{List && List.length > 0 ? (
				List
			) : List && !progressIndicator?.isLoading ? (
				<Typography variant="bodyLink" sx={{ width: '100%', padding: 1.2 }}>
					{t('view-claims.noClaimsFound')}
				</Typography>
			) : null}
		</Stack>
	);
};
