import {
	ThemeProvider as MuiThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';

import { FC } from 'react';
import { Frame } from 'appContainer/Frame';
import { I18nextProvider } from 'react-i18next';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline as MuiCssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import dayjs from 'dayjs';
import { deepmerge } from '@mui/utils';
import i18n from './i18n';
import { msalConfig } from 'b2c/b2cConf';
import { notistackConfig } from 'styles/theme/Notistack';
import { store } from './store/store';
import { theme } from './styles/theme/ThemeStyle';
import { themeSystem } from 'styles/theme/ThemeSystem';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const pca = new PublicClientApplication(msalConfig);

export const App: FC = () => {
	dayjs.extend(utc);
	dayjs.extend(timezone);

	return (
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>
				<MsalProvider instance={pca}>
					<StyledEngineProvider injectFirst>
						<MuiThemeProvider theme={deepmerge(themeSystem, theme)}>
							<MuiCssBaseline />
							<StyledComponentsThemeProvider theme={theme}>
								<SnackbarProvider {...notistackConfig}>
									<Frame />
								</SnackbarProvider>
							</StyledComponentsThemeProvider>
						</MuiThemeProvider>
					</StyledEngineProvider>
				</MsalProvider>
			</Provider>
		</I18nextProvider>
	);
};
