import ClaimsOperationIcon, {
	Sign,
} from 'components/commons/ClaimIcons/ClaimsOperationIcon.component';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from 'styles';
import { formatPrice, getShortDate } from 'utils/utils';

import { Divider } from '@mui/material';
import { RootState } from 'store/store';
import { Stack } from 'styles';
import TableBox from 'styles/box/TableBox';
import TableContainer from '@mui/material/TableContainer';
import TableFooterBox from 'styles/box/TableFooterBox';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ClaimPaymentDetails = () => {
	const { t } = useTranslation();
	const { claimData: claim, errorCode } = useSelector(
		(store: RootState) => store.claim,
	);
	const {
		amount: { components },
	} = claim;
	const {
		amount: {
			total: {
				retailAmount,
				contractualAdjustmentAmount,
				allowablePlanPay,
				memberPayAmount,
				total,
			},
		},
	} = claim;
	const rows = components;

	if (errorCode !== 0) {
		return null;
	}

	return (
		<TableBox>
			<TableContainer component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell align="center">
								<Typography variant="value1">{t('claim.table.serviceDate')}</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">{t('claim.table.serviceCode')}</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">{t('claim.table.st')}</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">{t('claim.table.totalRetail')}</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.MINUS} />
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{t('claim.table.contractaulAdjiustment')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">{t('claim.table.planPayment')}</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.PLUS} />
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{t('claim.table.memberOutOfPocket')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.EQUAL} />
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">{t('claim.table.total')}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row: any, index: number) => {
							const {
								retailAmount,
								serviceId,
								serviceName,
								contractualAdjustmentAmount,
								planPayAmount,
								allowablePlanPay,
								memberPayAmount,
								total,
							} = row;

							return (
								<TableRow key={index}>
									<TableCell component="th" scope="row" align="center">
										<Typography variant="body">
											{getShortDate(claim.claimDate)}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body">{serviceId ? serviceId : '-'}</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{serviceName ? serviceName.substring(0, 1) : '-'}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{retailAmount ? formatPrice(retailAmount) : formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{contractualAdjustmentAmount
												? formatPrice(contractualAdjustmentAmount)
												: formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{planPayAmount ? formatPrice(allowablePlanPay) : formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{memberPayAmount
												? formatPrice(memberPayAmount)
												: formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{total ? formatPrice(total) : formatPrice('0.00')}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}

						<TableRow>
							<TableCell colSpan={12}>
								<Divider light sx={{ width: '100%' }} />
							</TableCell>
						</TableRow>

						<TableRow sx={{ paddingBottom: '20px' }}>
							<TableCell
								component="th"
								scope="row"
								align="left"
								sx={{ paddingLeft: '30px', paddingBottom: '20px' }}
							>
								<Typography variant="bodyLink">{t('claim.table.total')}:</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{retailAmount ? formatPrice(retailAmount) : formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{contractualAdjustmentAmount
										? formatPrice(contractualAdjustmentAmount)
										: formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{allowablePlanPay
										? formatPrice(allowablePlanPay)
										: formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{memberPayAmount ? formatPrice(memberPayAmount) : formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{total ? formatPrice(total) : formatPrice('0.00')}
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<TableFooterBox>
				<Typography variant="bodyLink">{t('claim.legend.title')}</Typography>
				<Stack direction={'row'} sx={{ marginLeft: 2 }} gap={2}>
					<Typography variant="body">{t('claim.legend.exam')}</Typography>
					<Typography variant="body">{t('claim.legend.lens')}</Typography>
					<Typography variant="body">{t('claim.legend.contacts')}</Typography>
					<Typography variant="body">{t('claim.legend.frame')}</Typography>
					<Typography variant="body">{t('claim.legend.misc')}</Typography>
				</Stack>
			</TableFooterBox>
		</TableBox>
	);
};

export default ClaimPaymentDetails;
