import {
	Dialog as DialogMui,
	DialogProps as DialogPropsMui,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogTitle from './DialogTitle';
import { Link } from 'react-router-dom';
import palette from 'styles/theme/Palette';
import styled from 'styled-components';

type DialogProps = DialogPropsMui & {
	title: string;
	variant: 'dialog' | 'confirm' | 'uploadFileDialog';
	actions: React.ReactNode;
	close: React.ReactNode;
	events: boolean;
	type: string;
};

const StyledDialog = styled(DialogMui)``;

const Dialog: React.FC<DialogProps> = props => {
	const { t } = useTranslation();
	const { title, variant, actions, close, events, type, ...dialogProps } = props;

	let cookiesText;
	if (props.type === 'cookiesDialog') {
		cookiesText = (
			<Trans i18nKey="userMessagesUnread">
				{t('dialog.confirm.cookies.content1')}{' '}
				<Link
					to="https://www.google.com"
					target="_blank"
					style={{
						color: palette.primary.main,
						fontWeight: 700,
						textDecoration: 'underline',
					}}
				>
					{t('dialog.confirm.cookies.content2')}
				</Link>{' '}
				{t('dialog.confirm.cookies.content3')}.
			</Trans>
		);
	}
	return (
		<StyledDialog {...dialogProps} PaperProps={{ variant: variant }}>
			<DialogTitle title={title} children={close} />
			<DialogContent>
				{type === 'cookiesDialog' ? cookiesText : dialogProps.children}
			</DialogContent>
			{!!events && <DialogActions>{actions}</DialogActions>}
		</StyledDialog>
	);
};

export default Dialog;
