import 'dayjs/locale/en-gb';

import { Navigate, Route, Routes } from 'react-router-dom';
import { ViewClaimDetails, ViewClaims } from '../pages';
import { useCheckAlive, useLogin } from 'hooks/login.hook';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuthenticatedTemplate } from '@azure/msal-react';
import DialogConfirm from 'components/commons/Dialogs/DialogConfirm';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { RootState } from 'store/store';
import SharedLayout from 'components/Layout//SharedLayout/SharedLayout.component';
import UpdateFileDialog from 'components/commons/Dialogs/updateFileDialog';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
	className?: string;
}

export const Router: React.FC<Props> = () => {
	const { i18n } = useTranslation();
	const [dateJsStarted, setDatejsStarted] = useState(false);

	//login
	useLogin();

	// check connection
	useCheckAlive();

	const { alive } = useSelector((state: RootState) => state.healthCheck);

	useEffect(() => {
		dayjs.locale(i18n.language);
		setDatejsStarted(true);
	}, [i18n.language]);

	return (
		<AuthenticatedTemplate>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
				<DialogConfirm />
				<UpdateFileDialog />
				{!alive || !dateJsStarted ? null : (
					<Routes>
						<Route path="/view-claims" element={<SharedLayout />}>
							<Route path="/view-claims" element={<ViewClaims />} />
							<Route path="/view-claims/:id" element={<ViewClaimDetails />} />
						</Route>
						<Route path="*" element={<Navigate to="/view-claims" />} />
					</Routes>
				)}
			</LocalizationProvider>
		</AuthenticatedTemplate>
	);
};
