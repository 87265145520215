import dayjs from 'dayjs';

const getStorage = () => {
	return getCookiesInLocalStorage() === 'y' ? localStorage : sessionStorage;
};

export const cleanupStorage = () => {
	const cookiesPref = getCookiesInLocalStorage();
	const languagePref = cookiesPref === 'y' ? getLanguageStorage() : undefined;
	localStorage.clear();
	sessionStorage.clear();

	setCookiesInLocalStorage(cookiesPref);
	languagePref && setLanguageStorage(languagePref);
};

export const getIsLocationConfirmed = () => {
	const isRefreshed = sessionStorage.getItem('locationConfirmed'); // isRefreshed refer to token during session.
	return Boolean(isRefreshed);
};

export const setIsLocationConfirmed = (isConfirmed: boolean) => {
	sessionStorage.setItem('locationConfirmed', isConfirmed ? 'true' : '');
};

export const setLanguageStorage = (value: string) => {
	getStorage().setItem('i18nextLng', value);
};

export const getLanguageStorage = () =>
	getStorage().getItem('i18nextLng') || 'en-gb';

export const setCookiesInLocalStorage = (value?: 'y' | 'n') => {
	const cookiesValues = {
		acceptAll: value,
		date: dayjs(),
	};
	localStorage.setItem('providerCookies', JSON.stringify(cookiesValues));
};
export const getCookiesInLocalStorage = () => {
	const cookie = localStorage.getItem('providerCookies');
	if (cookie) {
		const cookieJson = JSON.parse(cookie);
		return cookieJson.acceptAll;
	}
};
